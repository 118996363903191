import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const server = "https://computer.djboy.no"

const prefetch = async (url) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  
  let response = await fetch(url, requestOptions);
  
  if (response.status === 200) {
    const data = await response.json();
  
    if (data) {
      return data;
    }
  }

  return [];
}

const smsPromise = new Promise(async (resolve, reject) => {
  const sms = await prefetch(`${server}/sms`);
  resolve(sms);
});

const mmsPromise = new Promise(async (resolve, reject) => {
  const mms = await prefetch(`${server}/mms`);
  resolve(mms);
});
Promise
  .all([smsPromise, mmsPromise])
  .then((data) => {
  root.render(
    <React.StrictMode>
      <App server={server} sms={data[0]} mms={data[1]}/>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
