function Sms(props) {
    return (
        <div className="messages">
        {
          props.messages.map((message, key) => <p key={`message-${key}`}><span>{message.nick}: </span>{message.message}</p>)
        }
      </div>
    )
}

export default Sms;
