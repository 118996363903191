
import React, { useState } from 'react';

import Sms from './Sms';
import Mms from './Mms';
import InfoMessage from './InfoMessage';
import Video from './Video';

import logo from './img/Djboy.png';
import qr from './img/qr.png';

import './App.css';

const ws = new WebSocket("wss://computer.djboy.no")

function App(props) {
  const [smsMessages, setSMSMessages] = useState(props.sms);
  const [mmsMessages, setMMSMessages] = useState(props.mms);
  
  ws.onmessage = async function (event) {
    const json = JSON.parse(event.data);
    if (json.type === "sms") {
      setSMSMessages(msgs => [json, ...smsMessages.slice(0, 10)])
    }

    if (json.type === "mms") {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      let response = await fetch(`${props.server}/mms/random-image`, requestOptions);

      if (response.status === 200) {
        const data = await response.json();

        if (data && data.image !== "") {
          // Make sure there are only one image from the archive
          const tmpMessages = mmsMessages;
          let archiveIndex = tmpMessages.findIndex((msg) => msg.archive === true);
          if (archiveIndex > -1) {
            tmpMessages.splice(archiveIndex, 1);
          }

          data.archive = true

          setMMSMessages(msgs => [json, data, ...tmpMessages.slice(0, 20)])
          return;
        }
      }

      setMMSMessages(msgs => [json, ...mmsMessages.slice(0, 20)])
    }
  }

  return (
    <div className="app">
      <div className="left">
        <Video />
        <Mms messages={mmsMessages} server={props.server} />
      </div>
      <div className="right">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <img src={qr} alt="qr" width="200px" />
        </div>
        <InfoMessage />
        <Sms messages={smsMessages} />
      </div>
    </div>
  );
}

export default App;
