import React, { useState, useEffect } from 'react';
import randomItems from 'random-items';

function renderCaption(message) {
    if (message.nick && message.message) {
        return <figcaption><span>{message.nick}</span>: {message.message}</figcaption>
    }

    if (message.message) {
        return <figcaption><span>Anonym</span>: {message.message}</figcaption>
    }

    if (message.nick) {
        return <figcaption><span>Anonym</span></figcaption>
    }

    return null;
}

function Mms(props) {
    const [currentMessages, setCurrentMessages] = useState([]);

    useEffect(() => {
        if (props.messages.length > 2) {
            const interval = setInterval(() => {
                setCurrentMessages(randomItems(props.messages, 2));
            }, 1000 * 15);

            return () => clearInterval(interval);
        } else {
            setCurrentMessages(props.messages.slice(0, 2));
        }
    }, [props.messages]);

    return (
        <div className="slideshow">
          {
            currentMessages.map((message, key) => {
              return (
                <figure key={`image-${key}`}>
                  <img src={`${props.server}/uploads/${message.image}`} alt="slide" />
                  {renderCaption(message)}
                </figure>
              )
            })
          }
        </div>
    );
}

export default Mms;