import React, { useEffect, useRef } from 'react';

function Video(props) {
    const videoRef = useRef(null);

    useEffect(() => {
      getVideo();
    }, [videoRef]);
  
    const getVideo = () => {
      navigator.mediaDevices
        .getUserMedia({ video: {  height: 720 } })
        .then(stream => {
          let video = videoRef.current;
          video.srcObject = stream;
          video.play();
        })
        .catch(err => {
          console.error("error:", err);
        });
    };

    return (
        <div>
            <video ref={videoRef} />
        </div>
    )
}

export default Video;
