import React, { useState, useEffect } from 'react';

const infoMessages = [
    "DJ BOY er kveldens vert, ingen er moderator",
    "Skann koden for å delta eller besøk 3310.djboy.no",
    "send requests på sms - 3310.djboy.no eller skann qr"
];

function InfoMessage() {
    const [currentInfoMessage, setCurrentInfoMessage] = useState(0);
    const [infoMessage, setInfoMessage] = useState(infoMessages[currentInfoMessage]);

    useEffect(() => {
        setInfoMessage(infoMessages[currentInfoMessage]);
    }, [currentInfoMessage]);
    
    useEffect(() => {
        setInterval(() => {
          setCurrentInfoMessage(Math.floor(Math.random()*infoMessages.length));
        }, 1000 * 30);
    }, [infoMessage])

      
    return (
        <div className="help">
            <p>{infoMessage}</p>
        </div>
    );
}

export default InfoMessage;